import { Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../component/Api/instance';
import Model from '../asserts/pngwing.com (2).png';
import '../YearTestPaper/style.css';
import Arrow from '../asserts/system-regular-161-trending-flat-white.gif';
import { Style } from '../YearTestPaper/style'; 
import Crown from '../asserts/crown.png';
import { UserContext } from '../Context';

export default function ModelInstruction() {
  const { sno } = useParams();
  const [data, setData] = useState([]);
  const [stages, setStages] = useState([]);
  const [options, setOptions] = useState([]);
  const [paperid, setPaperid] = useState(null);
  const [stageid, setStageid] = useState(null);
  const email = localStorage.getItem("userMail");
  const { Endpoint } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState();
  const [category, setCategory] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const navigate = useNavigate();
  const plan=localStorage.getItem("category");

  useEffect(() => {
    Institution();
    StageName();
    fetchData1();
  }, []);

  const fetchData1 = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewProfile.php`, { email: email });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
        setCategory(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Institution = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewModelMockInstitution.php`, { userId: email });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
        const yearobj = response.data?.map((item) => ({
          ...item,
          img: `https://nursingupdate.in/Nursing/controllers/api/admin/upload/${item.img}`
        })).filter(item => item.sno == sno);
        setData(yearobj);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const StageName = async () => {
    try {
      const res = await axiosInstance.post(`get/U_ViewStage.php`, { userId: email, institution_id: sno });
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
        setStages(res.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const PaperData = async (index, stageId, paperCategory) => {
      

      if (category.length > 0) {
      const studentCategory = category[0].plan_category;

      if (plan === "standard" && paperCategory != "premium") {
        try {
                  setPaperid(options[stageId][index].sno);
                  setStageid(stageId);
        
                  const res = await axiosInstance.post(`post/U_ModelMockTest.php`, {
                    id: email,
                    institution_id: sno,
                    mcq_id: options[stageId][index].sno,
                    stage_id: stageId,
                  });
                  const testEndObj = res.data.find(item => item.test_end);
                  if (testEndObj) {
                      console.log("Test End Time:", testEndObj.test_end);
                  } else {
                      console.log("Test end time not found.");
                  }
                
                  
                  if (res.status === 200) {
                    if (res.data.message === "timeout") {
                      navigate('/signin'); 
                    }
                    setSelectedIndex(index);
                    setSelectedIndexes((prevState) => ({
                      ...prevState,
                      [stageId]: index,
                    }));
                  }
                } catch (error) {
                  console.error("Error initializing paper:", error);
                }
      } else if (plan === "premium") {
        try {
                  setPaperid(options[stageId][index].sno);
                  setStageid(stageId);
        
                  const res = await axiosInstance.post(`post/U_ModelMockTest.php`, {
                    id: email,
                    institution_id: sno,
                    mcq_id: options[stageId][index].sno,
                    stage_id: stageId,
                  });
                  const testEndObj = res.data.find(item => item.test_end);
                  if (testEndObj) {
                      console.log("Test End Time:", testEndObj.test_end);
                  } else {
                      console.log("Test end time not found.");
                  }
                
                  
                  if (res.status === 200) {
                    if (res.data.message === "timeout") {
                      navigate('/signin'); 
                    }
                    setSelectedIndex(index);
                    setSelectedIndexes((prevState) => ({
                      ...prevState,
                      [stageId]: index,
                    }));
                  }
                } catch (error) {
                  console.error("Error initializing paper:", error);
                }
      } else {
        alert("You are not allowed to access this paper.");
      }
    } else {
      alert("Data is not available to determine access.");
    }
  };
  // const PaperData = async (index, stageId, paperCategory) => {
  //   setSelectedIndexes((prevState) => ({
  //     ...prevState,
  //     [stageId]: index,
  //   }));

  //   if (category.length > 0) {
  //     const studentCategory = category[0].plan_category;

  //     if (
  //       (plan === "standard" && paperCategory != "premium") ||
  //       plan === "premium"
  //     ) {
  //       try {
  //         setPaperid(options[stageId][index].sno);
  //         setStageid(stageId);

  //         const res = await axiosInstance.post(`post/U_ModelMockTest.php`, {
  //           id: email,
  //           institution_id: sno,
  //           mcq_id: options[stageId][index].sno,
  //           stage_id: stageId,
  //         });
  //         const testEndObj = res.data.find(item => item.test_end);
  //         if (testEndObj) {
  //             console.log("Test End Time:", testEndObj.test_end);
  //         } else {
  //             console.log("Test end time not found.");
  //         }
        
          
  //         if (res.status === 200) {
  //           if (res.data.message === "timeout") {
  //             navigate('/signin'); 
  //           }
  //           setSelectedIndex(index);
  //         }
  //       } catch (error) {
  //         console.error("Error initializing paper:", error);
  //       }
  //     } else {
  //       alert("You are not allowed to access this paper.");
  //     }
  //   } else {
  //     alert("Data is not available to determine access.");
  //   }
  // };

  const startTest = () => {
    if (paperid && stageid) {
      const selectedPaper = options[stageid].find((paper, index) => index === selectedIndexes[stageid]);
      navigate(`/institutiontest/${sno}/${paperid}/${stageid}`, {
        state: { paperName: selectedPaper ? selectedPaper.mcq_name : '' },
      });
    } 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(`get/U_ViewModelMockStagePaper.php`, {
          userId: email,
          id: sno,
        });
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            navigate('/signin'); 
          }
          setOptions(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const MainContainer = {
    backgroundColor: '#E1EEDD',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  };

  const Btn = {
    backgroundColor: '#F0A04B',
    border: 'none',
    borderRadius: '5px',
    fontWeight: 600,
    marginBottom: '3px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  return (
    <Style>
      {data.map((val) => (
        <div style={{ backgroundColor: '#E1EEDD', }} key={val.sno}>
          <Container fluid style={MainContainer}>
            <Row>
              <Col>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '95vw', padding: '20px' }}>
                  <div style={{ width: '700px' }}>
                    <Typography style={{ fontWeight: 600 }}>{val.institution_name}</Typography>
                    <Typography>{val.institution_desc}</Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={val.img} height="80px" alt="Model" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid style={{ padding: '20px' }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{maxHeight:"400px",overflow:"auto"}}>
                <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                  <Button fullWidth style={Btn}>
                    &nbsp;&nbsp;Model MCQ
                  </Button>
                  {stages.length > 0 ? (
                    stages.map(stage => (
                      <div key={stage.sno}>
                        <div style={{ display: 'flex', flexGrow: 2 }}>
                          <Button style={Btn}>
                            {stage.stage_name}
                          </Button>
                        </div>
                        {options[stage.sno] && options[stage.sno].length > 0 && (
                          options[stage.sno].map((paper, index) => (
                            <div key={index} >
                              <Button
                                fullWidth
                                style={{
                                  backgroundColor: selectedIndexes[stage.sno] === index ? "#183A1D" : "#fff",
                                  color: selectedIndexes[stage.sno] === index ? "#fff" : "#000",
                                  border: "none",
                                  borderBottom: "1px solid black"
                                }}
                                onClick={() => PaperData(index, stage.sno, paper.category)}
                              >
                                {paper.mcq_name}&nbsp;&nbsp;
                                {paper.category === "premium" ? <img src={Crown} height="20px" alt="Crown" /> : null}
                              </Button>
                            </div>
                          ))
                        )}
                      </div>
                    ))
                  ) : (
                    <Typography>No data available</Typography>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '40px' }}>
                  <Typography style={{ fontWeight: 600 }}>INSTRUCTION</Typography>
                  {val.institution_instruction && (
                    <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
                      {val.institution_instruction
                        .split(/\d+\.\s+/)
                        .filter(instruction => instruction.trim() !== "") 
                        .map((instruction, index) => (
                          <li key={index} style={{ marginBottom: "10px" }}>
                            {instruction.trim()}
                          </li>
                        ))}
                    </ul>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {paperid && stageid && (
                      <Button onClick={startTest} style={{ backgroundColor: '#183A1D', color: 'white', width: '100px' }}>
                        Start&nbsp;<img src={Arrow} height="20px" alt="Arrow" />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ))}
    </Style>
  );
}
