// Existing Code with added logic to format the instructions properly
import { Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../YearTestPaper/style.css';
import { Button } from '@material-ui/core';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import Crown from '../asserts/crown.png';
import Arrow from '../asserts/system-regular-161-trending-flat-white.gif';
import { axiosInstance } from '../component/Api/instance';
import { UserContext } from '../Context';
import { Style } from '../YearTestPaper/style';

export default function NonInstruction() {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [paperid, setPaperid] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [category, setCategory] = useState([]);
  const { sno } = useParams();
  const email = localStorage.getItem("userMail");
  const navigate = useNavigate();
  const { Endpoint } = useContext(UserContext);
  const plan=localStorage.getItem("category");

  useEffect(() => {
    fetchProfileData();
    fetchInstitutionData();
    fetchPaperData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewProfile.php`, {
        email: email
      });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin');
        } 
        setCategory(response.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchInstitutionData = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewNonNursingCategory.php`, {
        userId: email,
      });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
        const filteredData = response.data?.map((item) => ({
          ...item,
          img: `https://nursingupdate.in/Nursing/controllers/api/admin/upload/${item.img}`
        })).filter(item => item.sno == sno);
        setData(filteredData);
      }
    } catch (error) {
      console.error("Error fetching institution data:", error);
    }
  };

  const fetchPaperData = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewNonNursingPaper.php`, {
        id: sno,
        userId: email
      });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        }
        const filteredPapers = response?.data?.filter((paper) => paper.category_id == sno);
        setOptions(filteredPapers);
      }
    } catch (error) {
      console.error("Error fetching paper data:", error);
    }
  };

  const PaperData = async (index, paperCategory) => {
    const selectedPaper = options[index];

    if (category.length > 0 && options.length > 0) {
      const studentCategory = category[0].plan_category;

      if (plan === "standard" && paperCategory != "premium") {
        try {
          // Call API to initialize selected paper
          const response = await axiosInstance.post(`post/U_NonNursingTest.php`, {
            id: email,
            category_id: sno,
            paper_id: selectedPaper.sno
          });
          if (response.status === 200) {
            if (response.data.message === "timeout") {
              navigate('/signin'); 
            } 
            setSelectedIndex(index);
            setPaperid(selectedPaper.sno);
          }
        } catch (error) {
          console.error("Error initializing paper:", error);
        }
      } else if (plan === "premium") {
        try {
          // Call API to initialize selected paper
          const response = await axiosInstance.post(`post/U_NonNursingTest.php`, {
            id: email,
            category_id: sno,
            paper_id: selectedPaper.sno
          });
          if (response.status === 200) {
            if (response.data.message === "timeout") {
              navigate('/signin'); 
            } 
            setSelectedIndex(index);
            setPaperid(selectedPaper.sno);
          }
        } catch (error) {
          console.error("Error initializing paper:", error);
        }
      } else {
        alert("You are not allowed to access this paper.");
      }
    } else {
      alert("Data is not available to determine access.");
    }
  };

  const startTest = () => {
    if (paperid) {
      const selectedPaper = options[selectedIndex];
      navigate(`/aptitudetest/${sno}/${paperid}`, {
        state: {
          paperName: selectedPaper.paper_name,
        },
      }); 
    }
  };

  const MainContainer = {
    backgroundColor: "#E1EEDD",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
  };

  const Btn = {
    backgroundColor: "#F0A04B",
    border: "none",
    borderRadius: "5px",
    fontWeight: 600,
    marginBottom: "10px",
    height: "40px",
    display: "flex",
    justifyContent: "center"
  };

  // const renderInstructions = (instructions) => {
  //   return instructions.split(/(\d+\.\s)/).filter(Boolean).map((part, index) => {
  //     if (/\d+\.\s/.test(part)) {
  //       return <li key={index}><strong>{part}</strong></li>;
  //     }
  //     return <li key={index} style={{ marginLeft: "20px" }}>{part}</li>;
  //   });
  // };

  return (
    <Style>
      {data.map((val) => (
        <div key={val.sno} style={{ backgroundColor: "#E1EEDD", height: "100vh" }}>
          <Container fluid style={MainContainer}>
            <Row>
              <Col>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "95vw", padding: "20px" }}>
                  <div style={{ width: "700px" }}>
                    <Typography style={{ fontWeight: 600 }}>{val.category_name}</Typography>
                    <Typography>{val.category_desc}</Typography>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={val.img} height="80px" alt="Category" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={3} xl={3} style={{maxHeight:"400px",overflow:"auto"}}>
                <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                  <Button fullWidth style={Btn}>
                    &nbsp;&nbsp;Model MCQ
                  </Button>
                  {options.map((option, index) => (
                    <Button
                      key={index}
                      className="Option"
                      fullWidth
                      onClick={() => PaperData(index, option.category)}
                      style={{
                        backgroundColor: selectedIndex === index ? "#183A1D" : "#fff",
                        color: selectedIndex === index ? "#fff" : "#000",
                      }}
                    >
                      {option.paper_name}&nbsp;&nbsp;
                      {option.category === "premium" && <img src={Crown} height="20px" alt="Premium" />}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "40px" }}>
                  <Typography style={{ fontWeight: 600 }}>INSTRUCTION</Typography>
                  {val.category_instruction && (
    <ul style={{ listStyleType: "decimal", paddingLeft: "20px" }}>
      {val.category_instruction
        .split(/\d+\.\s+/)
        .filter(instruction => instruction.trim() !== "") // Filter out empty instructions
        .map((instruction, index) => (
          <li key={index} style={{ marginBottom: "10px" }}>
            {instruction.trim()}
          </li>
        ))}
    </ul>
  )}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {selectedIndex !== null && (
                      <Button
                        onClick={startTest}
                        style={{ backgroundColor: "#183A1D", color: "white", width: "100px" }}
                      >
                        Start&nbsp;<img src={Arrow} height="20px" alt="Start" />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ))}
    </Style>
  );
}
