import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {Container,Row,Col, Button} from 'react-bootstrap'
import Arrow from '../asserts/system-regular-161-trending-flat.gif'
// import AnswerBtn from "../SubjectModel/AnswerBtn";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Share1 from '../asserts/system-regular-1-share.gif'
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Correct from '../asserts/Component 322 – 3.png'
import Rong from '../asserts/Component 26 – 5.png'
import Web from '../asserts/_x36__stroke.png'
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../component/Api/instance";
import CountdownTimer from "./Countdown";
import { useContext } from "react";
import { UserContext } from "../Context";
import { useLocation } from "react-router-dom";
import ScoreBoard from '../asserts/Score Board@2x.jpg'
import {
 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
function InstitutionTest() {
  
  const Btn={
    backgroundColor:"white",
    color:"black",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    width:"150px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
 
  const Btn1={
    backgroundColor:"white",
    color:"black",
    border:"1px solid #183A1D ",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    width:"150px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center"
  }
  const PreviewBtn={
    backgroundColor:"#F0A04B",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"7px",
    borderRadius:"5px"
  }
  const AlertBtn={
    backgroundColor:"white",
    color:"black",
    border:"1px solid black",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    width:"100px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"7px"
  }
  const Share={
    backgroundColor:"#183A1D",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
     padding:"7px",
    borderRadius:"5px"
  }
  const Finsh={
    backgroundColor:"#183A1D",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    marginTop:"30px",
    width:"200px",
    alignItems:"center"
  }
  const noCopyStyle = {
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    MsUserSelect: "none",
    fontFamily:"Answer"
  };
  
  const [Previewopen, setPreviewopen] = React.useState(false);

  const PreviewhandleClickOpen = () => {
    setPreviewopen(true);
  };
  const PreviewhandleClose = () => {
    setPreviewopen(false);
    navigate("/model");
  };

  const [open, setOpen] = React.useState(false);
  const [finish,setfinish]=React.useState(false);
  const { Endpoint } = useContext(UserContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const FinishTest=()=>{
    setfinish(true);
  }
  const FinishTestClose=()=>{
    setfinish(false);
    navigate("/model");

  }
  const location=useLocation();
  const paperName = location.state?.paperName;
  
  const [CrtBtnopen, setCrtBtnOpen] = React.useState(false);

  const CrtClickOpen = () => {
    setCrtBtnOpen(true);
  };

  const CrtClickClose = () => {
    setCrtBtnOpen(false);
    navigate("/model");
  };
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const { sno, paperid ,stageid} = useParams();
  const [options, setOptions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsNo, setQuestionsNo] = useState([]);
  const [questioncount, setQuestioncount] = useState()
  const [responseData, setResponseData] = useState(null);
  const [responsecount, setResponsecount] = useState(null);
  const email=localStorage.getItem("userMail");
  const [questionStatus, setQuestionStatus] = useState([]);
  const [whatsapp,setWhatsapp]=useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // Define selected option index state
  const [selectedOption, setSelectedOption] = useState(""); // Define selected option state
  const [optionIndexMapping, setOptionIndexMapping] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openExplanationDialog, setOpenExplanationDialog] = useState(false);
  const [selectedExplanation, setSelectedExplanation] = useState("");

  const handleExplanationClick = (explanation) => {
    setSelectedExplanation(explanation);
    setOpenExplanationDialog(true);
  };

  const handleExplanationClose = () => {
    setOpenExplanationDialog(false);
    setSelectedExplanation("");
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchQuestionSno();
  }, []);
  const fetchQuestions = async (questionId) => {
    try {
      const res = await axiosInstance.post(
        `get/U_ViewModelMockQuestions.php`,
        {
          userId:email,
          institutionId: sno,
          mcqId: paperid,
          questionId: questionId,
          stageId:stageid
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setQuestions(res.data);
          // setSelectedQuestionIndex(0);
       
      }
     
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    Institution();
  }, []);

  const Institution = async () => {
    try {
      const res = await axiosInstance.post(
        `get/U_ViewStaticInfo.php`,
        {
          userId: email
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setWhatsapp(res.data);
       
      }
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const styles = {
    mainContainer: {
      width: "98vw",
    },
    title: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      height:"50px",
      width: "100%",
      justifyContent: "space-between",
      padding: "10px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      zIndex: "1",
    },
  }
  const registerDatas = async () => {
    
    try {
      const res = await axiosInstance.post(
        `get/U_ModelMockPreview.php`,
        {
          userId:email,
          institution_id: sno,
          mcq_id: paperid,
          stage_id:stageid
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          if (Array.isArray(res.data)) {
            setResponseData(res.data);
           
          } else {
            console.error("Unexpected data format:", res.data);
          }
        
      }
     
    } catch (err) {
     

      console.error("Error fetching data:", err);
    }
  };
  const registerDatas1 = async () => {
    
    try {
      const res = await axiosInstance.post(
        `get/U_ModelMockMark.php`,
        {
          userId:email,
          category_id:stageid,
          paper_id:paperid,
          institution_id:sno
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          if (Array.isArray(res.data)) {
            setResponsecount(res.data);
           
          } else {
            console.error("Unexpected data format:", res.data);
          }
       
      }
     
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  const handleQuestionChange = async (index) => {
    
    // console.log("Selected question index:", index);
    if (index >= 0 && index < questionsNo.length) {
      const selectedSno = questionsNo[index].sno;
      

      fetchQuestions(selectedSno);
      console.log(index);
      setSelectedQuestionIndex(index);
      console.log(selectedQuestionIndex);
      
      if (index === questionsNo.length - 1) {
        await registerDatatest(questionsNo[selectedQuestionIndex].sno);
      }
    }
  };
  const fetchQuestionSno = async () => {
    try {
      const res = await axiosInstance.post(
        `get/U_ViewModelMockQuestionCount.php`,
        {
          userId:email,
          institutionId: sno,
          mcqId: paperid,
          stageId:stageid
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setQuestionsNo(res.data);
          const count = res.data.length;
          setQuestioncount(count);
          
          console.log(res.data);
          setSelectedQuestionIndex(0);
       
      }
      
    } catch (error) {
      console.error("Error fetching question numbers:", error);
    }

  };
  const handleNextQuestion = async () => {
    const nextIndex = selectedQuestionIndex + 1;
    if (nextIndex < questionsNo.length) {
      const selectedSno = questionsNo[nextIndex].sno;
  
      // Save current question data
      await registerDatatest(questionsNo[selectedQuestionIndex].sno);
   
      // Fetch the next question
      await fetchQuestions(selectedSno);
      setSelectedQuestionIndex(nextIndex);
  
      // Preselect the previously selected option for the next question (if available)
      setSelectedOption(selectedOptions[nextIndex] || ''); // Get saved option for next question
      setSelectedOptionIndex(optionIndexMapping[selectedOptions[nextIndex]] || null); // Use saved index or null
  
      // Update question status
      setQuestionStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[selectedQuestionIndex] = selectedOptions[selectedQuestionIndex] ? 'answered' : 'skipped';
        return newStatus;
      });
    }
    else if (nextIndex === questionsNo.length) {
      // Handle the last question case
      await registerDatatest(questionsNo[selectedQuestionIndex].sno);
      setQuestionStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[selectedQuestionIndex] = selectedOption ? 'answered' : 'skipped';
        return newStatus;
      });
      // Optionally, navigate to a different page or show a message
    }
  };
  const registerDatatest = async (questionId) => {
    
    try {
      const res = await axiosInstance.post(
        `put/U_updateModelMock.php`,
        {
          id:email,
          question_id: questionId,
          answer: selectedOption,
          institution_id: sno,
          stage_id: stageid,
          mcq_id: paperid
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          console.log(res.data);
       
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }

  };
 

  const handleSkipQuestion = async () => {
    // Update status for the current question
    setQuestionStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[selectedQuestionIndex] = 'skipped';
      return newStatus;
    });
  
    // Call handleNextQuestion to proceed to the next question
    await handleNextQuestion();
  };
  useEffect(() => {
    if (optionIndexMapping[selectedQuestionIndex] !== undefined) {
      setSelectedOptionIndex(optionIndexMapping[selectedQuestionIndex]);
    } else {
      setSelectedOptionIndex(null); // Reset if no option was selected previously
    }
  }, [selectedQuestionIndex, optionIndexMapping]);
  
  const handleOptionSelect = (optionIndex) => {
    const selectedOptionText = questions[0][`option${optionIndex}`];
    setSelectedOptionIndex(optionIndex);
    setSelectedOption(selectedOptionText);
  
    // Save selected option for the current question
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[selectedQuestionIndex] = selectedOptionText; // Save selected option for current question
      return updatedOptions;
    });
  
    // Update the mapping for option index per question
    setOptionIndexMapping((prevMapping) => ({
      ...prevMapping,
      [selectedQuestionIndex]: optionIndex
    }));
  
    console.log("Selected option data:", selectedOptionText);
  };

  const [colorSwap,setColorSwap]=useState(true);
  function swapping() {
    setColorSwap(!colorSwap); // Toggle between zoomed-in and zoomed-out
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const Active = {
    cursor: 'pointer',
    transition: 'transform 0.3s ease', // Smooth transition
    transform: 'scale(1)', // Normal size (zoomed out)
    width: 'auto', // Adjust width to original image size
    height: '150px', // Default height
  };
  
  const DisActive = {
    cursor: 'pointer',
    transition: 'transform 0.3s ease', // Smooth transition
    transform: 'scale(1.5)', // Zoomed-in size
    width: 'auto',
    height: '200px', // Adjust height when zoomed in
    marginLeft:"120px"
  };
  
  const handleClick = () => {
    if (windowWidth > 600) {
      swapping();
    }
  };


  const isImage = (url) => {
    return url && url.match(/\.(jpeg|jpg|gif|png|svg|webp|jfif)$/) != null;
  };
  return (
    <div style={{ backgroundColor: "#F2F1EB", padding: "10px",height:"100vh"}}>
      <Container fluid style={styles.mainContainer}>
                      <Row>
                        <Col>
                          <div  style={styles.title}>
                            <div>
                              <Typography style={{ fontWeight: 600 }}>{paperName}</Typography>
                            </div>
                            <div><CountdownTimer paper={paperid} stageId={stageid} modelid={sno} /></div>

                            <div>
                              <Typography style={{ fontWeight: 600 }}>
                              {selectedQuestionIndex + 1}/{questionsNo.length}
                              </Typography>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
      <Container fluid >
        <Row style={{ marginLeft:"1px",zIndex:-1 }}>
          <Col xs={12} sm={12} md={12} lg={7} xl={7} style={{ backgroundColor: "#E1EEDD" }}>
            <div style={{ paddingTop: "30px" }}>
              <div>
              
               {questions[0] ? (
  <div style={noCopyStyle}>
    <p style={{fontWeight:600,letterSpacing:"1px"}} >{`${selectedQuestionIndex + 1}. ${questions[0].questions}`}</p>
    {
                    questions[0].image ? <img src={`https://nursingupdate.in/Nursing/controllers/api/admin/upload/${questions[0].image}`} height="200px" alt="Uploaded Image"
                    onClick={handleClick}
                    style={colorSwap ? Active : DisActive}/> : null
                  }
                  <ol type="a" style={{ paddingLeft: "0", listStylePosition: "inside" }}>
  {[1, 2, 3, 4].map((option) => (
    <li key={option} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <input
          type="radio"
          name="options"
          value={option}
          checked={selectedOptionIndex === option}
          onChange={() => handleOptionSelect(option)}
          style={{ marginRight: "10px" }}
        />
        {isImage(questions[0][`option${option}`]) ? (
          <img
          src={`https://nursingupdate.in/Nursing/controllers/api/admin/upload/${questions[0][`option${option}`]}`} 
            alt={`Option ${option}`}
            style={{ maxHeight: "80px", marginLeft: "10px" }}
          />
        ) : (
          <span>{questions[0][`option${option}`]}</span>
        )}
      </label>
    </li>
  ))}
</ol>
  </div>
) : (
  <p>No question selected</p>
)}
              </div>


              
              <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", height: "300px", marginBottom: "20px" }}>
                <Button style={Btn} onClick={handleSkipQuestion}>Skip</Button>&nbsp;&nbsp;&nbsp;
                <Button style={Btn} onClick={handleNextQuestion}>Save & Next <img src={Arrow} height="25px" /></Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{ backgroundColor: "#FEFBE9", zIndex: 1 }} >
            <Typography style={{ fontWeight: 600, textAlign: "center", paddingTop: "30px" }}>QUESTION STATUS</Typography>
            <div style={{ paddingTop: "60px", width: "auto", marginTop: "30px", padding: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#183A1D", height: "20px", width: "20px", borderRadius: "50%" }}></div>
                  &nbsp;&nbsp;<span style={{ marginTop: "-3px", fontWeight: 600 }}>Answered</span>

                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "red", height: "20px", width: "20px", borderRadius: "50%" }}></div>
                  &nbsp;&nbsp;<span style={{ marginTop: "-3px", fontWeight: 600 }}>Skipped</span>

                </div>
              </div>
                <div
                  style={{
                    backgroundColor: "#f6f6f6",
                    borderRadius: "15px",
                    height:"300px",
                    overflow: "auto",
                    width:"100%",
                    paddingLeft:"40px",
                    marginTop:"20px",
                    display:"flex",
                    flexWrap:"wrap",
                  }}
                >
                  {Array.isArray(questionsNo) && questionsNo.length > 0 ? (
                  questionsNo.map((question, index) => (
                    <button
                      key={index}
                      style={{
                        backgroundColor: questionStatus[index] === 'skipped' ? 'red' :
                        questionStatus[index] === 'answered' ? 'green' :
                        'white',
                        color: "black",
                        border: "none",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        width: "50px",
                        height: "50px",
                        fontWeight: 600,
                        marginBottom: "10px",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "50%",
                        padding: "10px",
                        marginLeft:"30px"
                      }}
                      onClick={() => handleQuestionChange(index)}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <Typography>No questions available</Typography>
                )}

                </div>

             
              <div >

                <div className="d-grid " style={{display:"flex",justifyContent:"center"}}>
                  <Button style={Finsh} size="lg" onClick={handleClickOpen}>FINISH TEST</Button>
                </div>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">

                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to finish the Test ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button style={Share} onClick={() => {
                      FinishTest();
                      registerDatas1(); // Fetch data when finishing the test

                      registerDatas(); // Fetch data when finishing the test
                    }}>Yes,Finish Test</Button>
                    <Button style={AlertBtn} onClick={handleClose} autoFocus>
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
        open={finish}
        onClose={FinishTestClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
                    <DialogContentText style={{ fontWeight: 600, color: "black" }}>
                      Thank you, Your test is submitted successfully.
                      {responsecount ? (
                        responsecount.length > 0 ? (responsecount.map((item, index) => (
                        <div key={index}>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                              Correct Answer: {item.correct_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Wrong Answer: {item.incorrect_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Skipped: {item.skip_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Negative Mark: {item.negative_marks}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Total Mark: {item.total_marks}
                          </Typography>
                          
                        </div>
                        ))
                        ) : (
                          <Typography>No data available</Typography>
                        )
                      ) : (
                        <Typography>Loading data...</Typography>
                      )}
                    </DialogContentText>
          
        </DialogContent>
        <DialogActions style={{display:"flex",justifyContent:"space-around"}}>
        <button style={PreviewBtn} onClick={PreviewhandleClickOpen}><VisibilityIcon style={{fontSize:"20px",marginTop:"4px"}}/>&nbsp; Preview</button>
        <button
  style={Share}
  onClick={() => {
    // Ensure responsecount is valid and has at least one item
    if (responsecount && responsecount.length > 0) {
      const score = responsecount[0]?.total_marks || 0;
      const totalMarks = responsecount[0]?.total_questions || 0;
      
      const shareText = `I scored ${score}/${totalMarks} in the Nursing Update Test!`;

      // Create a canvas to draw the image and text
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      // Set canvas dimensions
      canvas.width = 800; // width of the final image
      canvas.height = 450; // height of the final image

      // Load the base image that will be shared (replace with your image source)
      const image = new Image();
      image.src = ScoreBoard; // Your image source (e.g., '/path/to/your/image.png')
      image.onload = () => {
        // Draw the image on the canvas
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Set font properties for the score text
        context.font = '30px Arial';
        context.fillStyle = 'black'; // Text color
        context.textAlign = 'right';

        // Split text into two lines
        const lines = shareText.split(' Nursing '); // Move "in the Nursing Update Test" to the next line

        // Draw the score text
        context.fillText(lines[0], canvas.width / 2, canvas.height / 2 - 20); // First line
        context.fillText('Nursing Update Test!', canvas.width / 2, canvas.height / 2 + 20); // Second line

        // Convert canvas to image (base64 or blob)
        canvas.toBlob((blob) => {
          const imageUrl = URL.createObjectURL(blob);

          // Copy score to clipboard
          navigator.clipboard.writeText(shareText).then(() => {
            // After copying, show an alert and then redirect to Instagram
            if (window.confirm("Score copied to clipboard! Click OK to share the image on Instagram.")) {
              // Trigger download of the generated image
              const link = document.createElement('a');
              link.href = imageUrl;
              link.download = 'score-image.png'; // Name of the file to download
              link.click();

              // Open Instagram in a new tab
              window.open('https://www.instagram.com/', '_blank');
            }
          }).catch((err) => {
            console.error("Failed to copy score: ", err);
          });
        }, 'image/png'); // Convert canvas to PNG format
      };
    } else {
      alert("No score data available.");
    }
  }}
>
  <img src={Share1} height="20px" style={{ marginTop: '4px' }} alt="Share" />
  &nbsp; Share score
</button>
 
          <button style={AlertBtn} onClick={FinishTestClose} autoFocus>
            Done
          </button>
        </DialogActions>
      </Dialog>
      <div>
      <Dialog
        onClose={PreviewhandleClose}
        aria-labelledby="customized-dialog-title"
        open={Previewopen}
        maxWidth="xl"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Test Preview
          <IconButton
            aria-label="close"
            onClick={PreviewhandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Q.No</TableCell>
                  <TableCell>Student Answer</TableCell>
                  <TableCell>Correct Answer</TableCell>
                  <TableCell>Explanation</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {responseData ? (
                  responseData.length > 0 ? (
                    responseData.map((question, index) => (
                      <TableRow key={question.question_id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {question.student_answer || ""}
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {question.correct_answer}
                        </TableCell>
                        <TableCell
                          style={{ cursor: "pointer" }}
                          onClick={() => handleExplanationClick(question.explanation)}
                        >
                          {question.explanation}
                        </TableCell>
                        <TableCell>
                          {question.student_answer == null || question.student_answer === ""
                            ? null
                            : question.student_answer === question.correct_answer ? (
                                <img src={Correct} height="30px" alt="Correct" />
                              ) : (
                                <img src={Rong} height="30px" alt="Wrong" />
                              )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography>No data available</Typography>
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography>Loading data...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button style={Btn1} autoFocus onClick={PreviewhandleClose}>
            Ok
          </Button>
          <div style={{ display: "flex", justifyContent: "flex-end", width: "400px" }}>
            <Typography>
              <img src={Web} height="30px" style={{ marginTop: "7px" }} />
            </Typography>
            &nbsp;&nbsp;
            {whatsapp.length > 0 ? (
              whatsapp.map((d) => (
                <div key={d.id}>
                  <a
                    href={d.whatsapplink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#183A1D",
                        color: "white",
                        height: "40px",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {d.whatsapplink}
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <div>No data available</div>
            )}
          </div>
        </DialogActions>
      </Dialog>

      {/* Explanation Dialog */}
      <Dialog
        open={openExplanationDialog}
        onClose={handleExplanationClose}
        aria-labelledby="explanation-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="explanation-dialog-title">Explanation</DialogTitle>
        <DialogContent dividers>
          <Typography>{selectedExplanation}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExplanationClose} style={PreviewBtn}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>

              </div>
            </div>
            

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default InstitutionTest;




