import { Typography } from '@mui/material'
import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import BG from '../../asserts/ab.png'
import CheckIcon from '@mui/icons-material/Check';
import Mission from '../../asserts/mission.webp'
import Vision from '../../asserts/mission.jpg'
import Update from '../../asserts/nursing_update.jpg'
import Nursing from '../../asserts/nursing_service.jpg'
import Footer from '../footer';
import Tick from '../../asserts/49827_check_done_icon.png'

export default function AboutUs() {
  const MainContainer={
    background:`url(${BG})`,
    height:"40vh",
    backgroundSize:"cover",
  }
  const MainCol={
    display:"flex",
    backgroundColor:"rgba(255, 255, 255, 0.301)",
    height:"70vh",
    width:"98vw",
    alignItems:"center"
  }
  const MainDiv={
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start",
    width:"99vw",
  }
  const ColStyle={
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
  return (
    <div>
      <Container fluid style={MainContainer}>
        <Row>
          <Col style={MainCol}>
            <div style={MainDiv}>
            <div>
                <Typography variant='h5' style={{textAlign:"center",fontWeight:500,paddingBottom:"200px",fontFamily:"about"}}>Nursing update access education for all by democratizing<br/> its availability Join our educational<br/> revolution and enhance your learning experience on India's leading platform.</Typography>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{marginTop:"30px"}}>
        <Row>
          <Col xs={{order:2}} sm={{order:2}}  lg={{order:1}} xl={{order:1}} md={{ order: 1 }} style={ColStyle}>
            <div>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>Our Mission</Typography>
              <Typography><img src={Tick}/>&nbsp; Thinking is the capital, </Typography>
              <Typography><img src={Tick}/>&nbsp; Enterprise is the way,  </Typography>
              <Typography><img src={Tick}/>&nbsp; Hard work is the solution </Typography>
            </div>
          </Col>
          <Col  xs={12} sm={12} md={6}  lg={{order:2}} xl={{order:2}} style={ColStyle}>
          <div>
          <img src={Mission} height="200px"/>
          </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundColor:"#FEFBE9",marginTop:"30px"}}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
            <div>
              <img src={Vision} height="300px"/>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div style={{marginTop:"40px"}}>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>Our Vision</Typography>
              <div style={{width:"90%"}}>
              <ul style={{textAlign:"justify",listStyleType:"none"}}>
                <li>
                  <img src={Tick}/>&nbsp;Our vision for the nursing coaching institute is to provide comprehensive and innovative coaching services to aspiring nurses across the globe. We aim to create a supportive environment that fosters growth and development, equipping our students with the skills and knowledge they need to excel in their nursing careers.  </li><br/>
                <li>
                <img src={Tick}/>&nbsp;Our institute will prioritize evidence-based teaching and mentorship, preparing our students to become leaders in their profession. With a commitment to constantly improving and adapting to changing technologies and nursing practices, we envision our institute as a top choice for nursing competitive exams.  
                </li>
              </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundImage:`url(${Update})`,height:"40vh"}}>
        <Row>
          <Col style={{display:"flex",alignItems:""}}>
          <div>
            <Typography variant='h5' style={{fontWeight:600,paddingTop:"230px"}}>Why to choose Nursing Update?</Typography>
          </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{marginTop:"30px"}}>
        <Row>
          <Col>
            <div style={{width:"88%"}}>
              <ul style={{listStyleType:"none",textAlign:"justify"}}>
                <li>
                <img src={Tick}/>&nbsp;Nursing Update, a unit of Venba Coaching Institute, started as a Youtube channel and received overwhelming response prompting its conversion into a full-fledged coaching institute in 2021. Since then, it has achieved significant success in providing quality coaching and training to nursing students, including high pass rates and successful placements in top healthcare institutions.
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;The institute has also gained a strong reputation for its dedicated faculty, modern teaching methods, and student-focused approach.Our platform provides unparalleled information and expertise, with a forward-thinking outlook, practicality, robust functionalities, and personalized guidance and support throughout the journey of preparation
                </li><br/>
                <li>
                <img src={Tick}/>&nbsp;As a responsible provider of education to future nurses, our institute has ensured to constantly upgrade our knowledge and practices. We have revamped our curriculum and methods of teaching, and redefined our standards to produce more qualified and skilled nurses who can add value to the healthcare system. Our commitment to providing the best education and training will create a positive impact in enhancing the quality of patient care and improving the overall health outcomes.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundColor:"#FEFBE9",marginTop:"30px"}}>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div>
            <img src={Nursing} height="300px"/>
          </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} style={ColStyle}>
          <div style={{marginTop:"40px"}}>
              <Typography variant='h5' style={{paddingBottom:"20px",fontWeight:600}}>Our Services</Typography>
              <div style={{width:"90%"}}>
              <ul style={{textAlign:"justify",listStyleType:"none"}}>
                <li> <img src={Tick}/>&nbsp;We specialize in training students for national level nursing exams as well as preparing them for opportunities in Dubai, Abu Dhabi, Qatar, UK, Ireland, and Australia.   </li><br/>
                <li>
                <img src={Tick}/>&nbsp;Our services include free processing and visa services for working in these countries. Our experienced instructors and consultants provide personalized support to help students achieve their goals and succeed in their careers 
                </li>
              </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer/>
    </div>
  )
}
